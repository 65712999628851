import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import { displayDuration, penniesToDollars } from '../../util/helper';

import ridePhotoImage from '../../images/ride_photo.png';

import './index.scss';

const moment = require('moment-timezone');

export default class GuestRides extends Component {
  render() {
    const {
      rides,
      onClickInvoice,
      onClickRefund,
      onClickRideMap,
      onClickRidePhoto,
      onClickScooter,
    } = this.props;
    const tableHeight = rides.length * 100;
    return (
      <div className="tableContainer">
        <ReactTable
          ref={(r) => {
            this.selectTable = r;
          }}
          data={rides}
          showPaginationBottom={false}
          pageSize={rides.length}
          style={{
            height: `${tableHeight}px`,
          }}
          columns={[
            {
              className: 'guest_ride_cell',
              id: 'is_group_ride',
              width: 60,
              maxWidth: 60,
              Cell: () => <div className="guest_cell">Guest</div>,
            },
            {
              id: 'vehicle_name',
              accessor: (d) =>
                d.vehicle ? `${d.vehicle.model_name} ${d.vehicle.name}` : d.vehicle_name,
              maxWidth: 150,
              className: 'guest_ride_cell',
              Cell: (props) => {
                const isGuestRide = props.original.guest != null;
                let style = isGuestRide ? 'underline scooterLink' : 'underline scooterLink';
                return (
                  <div
                    onClick={(event) => {
                      onClickScooter(props.original.vehicle.hash);
                      event.stopPropagation();
                    }}>
                    <span className={style}>{props.value}</span>
                  </div>
                );
              },
            },
            {
              className: 'guest_ride_cell',
              accessor: 'start_zone_name',
              maxWidth: 100,
              Cell: (props) => (
                <div
                  onClick={(event) => {
                    onClickRideMap(props.original);
                    event.stopPropagation();
                  }}>
                  <span className="underline scooterLink">{props.value}</span>
                </div>
              ),
            },
            {
              className: 'guest_ride_cell',
              accessor: 'start_time',
              Cell: (props) => (
                <Fragment>
                  {props.value &&
                    moment(props.value).tz('America/Los_Angeles').format('MMM DD YYYY, h:mm a')}
                </Fragment>
              ),
              maxWidth: 175,
              sortMethod: (a, b) => {
                if (a === b) {
                  return 0;
                }
                return moment(a).isAfter(b) ? 1 : -1;
              },
            },
            {
              className: 'guest_ride_cell',
              accessor: 'end_time',
              Cell: (props) => (
                <Fragment>
                  {props.value &&
                    moment(props.value).tz('America/Los_Angeles').format('MMM DD YYYY, h:mm a')}
                </Fragment>
              ),
              maxWidth: 175,
              sortMethod: (a, b) => {
                return a === b ? 0 : moment(a).isAfter(b) ? 1 : -1;
              },
            },
            {
              className: 'guest_ride_cell',
              accessor: 'duration',
              Cell: (props) => <Fragment>{displayDuration(props.value)}</Fragment>,
              maxWidth: 70,
              sortMethod: (a, b) => {
                if (a === b) {
                  return 0;
                }
                return a < b ? 1 : -1;
              },
            },
            {
              className: 'guest_ride_cell currency',
              accessor: 'charged_amount',
              maxWidth: 70,
              Cell: (props) => <Fragment>{penniesToDollars(props.value)}</Fragment>,
              sortMethod: (a, b) => {
                if (a === b) {
                  return 0;
                }
                return a < b ? 1 : -1;
              },
            },
            {
              id: 'total_charge',
              className: 'guest_ride_cell',
              maxWidth: 70,
              Cell: () => <div />,
            },
            {
              className: 'guest_ride_cell',
              accessor: 'invoice',
              maxWidth: 125,
              width: 125,
              Cell: (props) => {
                return (
                  <div style={{ textAlign: 'center' }}>
                    <input
                      type="button"
                      value="Invoice"
                      onClick={(event) => {
                        event.stopPropagation();
                        onClickInvoice && onClickInvoice(props.original);
                      }}
                    />
                  </div>
                );
              },
            },
            {
              className: 'guest_ride_cell',
              accessor: 'ride_end_reason',
              maxWidth: 300,
            },
            {
              className: 'guest_ride_cell',
              accessor: 'payment_status',
              maxWidth: 300,
              Cell: (props) => <div style={{ textAlign: 'center' }}>{props.value}</div>,
            },
            {
              className: 'guest_ride_cell',
              accessor: 'payment_status',
              maxWidth: 125,
              width: 125,
              Cell: (props) => (
                <div style={{ textAlign: 'center' }}>
                  {props.value === 'paid' &&
                    props.original.charged_amount > 0 &&
                    new Date(props.original.end_time) > new Date('2019-07-29') && (
                      <input
                        type="button"
                        value="Refund"
                        onClick={(event) => {
                          onClickRefund(props.original.id, props.original.host_ride_id);
                          event.stopPropagation();
                        }}
                      />
                    )}
                </div>
              ),
            },
            {
              className: 'guest_ride_cell',
              accessor: 'end_ride_photo_id',
              width: 100,
              Cell: (props) => (
                <div style={{ textAlign: 'center' }}>
                  {props.value !== null && (
                    <img
                      src={ridePhotoImage}
                      alt="Close Modal Button"
                      onClick={(event) => {
                        onClickRidePhoto(props.original);
                        event.stopPropagation();
                      }}
                      className=""
                      style={{
                        paddingTop: '3px',
                        alignSelf: 'center',
                      }}
                    />
                  )}
                </div>
              ),
            },
          ]}
        />
      </div>
    );
  }
}

GuestRides.propTypes = {
  rides: PropTypes.array,
  onClickInvoice: PropTypes.func.isRequired,
  onClickRefund: PropTypes.func.isRequired,
  onClickRideMap: PropTypes.func.isRequired,
  onClickRidePhoto: PropTypes.func.isRequired,
  onClickScooter: PropTypes.func.isRequired,
  refundRide: PropTypes.func,
};
