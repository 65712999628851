import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import { displayDuration, penniesToDollars } from '../../util/helper';
import GuestRides from '../GuestRides';
import { getRidesById } from '../../api';

import ridePhotoImage from '../../images/ride_photo.png';

import './index.scss';

const moment = require('moment-timezone');

export default class Rides extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedRows: [],
      groupRides: {},
    };
  }

  loadGroupRides = (hostRide) => {
    const groupRideIds = hostRide.group_rides.map((groupRide) => groupRide.id);
    getRidesById(groupRideIds).then(({ rides }) => {
      const oldGroupRides = this.state.groupRides;
      oldGroupRides[hostRide.id] = rides;
      this.setState({ groupRides: oldGroupRides });
    });
  };

  /**
   * Since guest ride data is loaded only when a host ride row is expanded to show guest rides,
   * guest ride data is not automatically refreshed after issuing a guest ride refund (which is
   * what happens when a host ride is refunded). This is an ugly workaround for that, to manually
   * reload the guest rides for a host ride when one of the guest rides is refunded. Since
   * refundRide() does not return a Promise, we don't know when the refund will actually have
   * completed. The guest rides are reloaded after a brief delay, on the assumption that by
   * then, the refund will have been processed.
   *
   * @param {String} guestRideId ID of the guest ride to refund
   * @param {String} hostRideId ID of the host ride, so that guest rides can be refreshed
   */
  refundGuestRide = (guestRideId, hostRideId) => {
    this.props.refundRide(guestRideId);
    setTimeout(() => {
      const hostRide = this.props.rides.find((r) => r.id === hostRideId);
      if (hostRide) {
        this.loadGroupRides(hostRide);
      }
    }, 2000);
  };

  render() {
    const {
      rides,
      onClickInvoice,
      onClickRideMap,
      onClickRidePhoto,
      onClickScooter,
      tableHeight = 800,
    } = this.props;
    return (
      <div className="tableContainer">
        <h2>Rides Table</h2>
        <ReactTable
          ref={(r) => {
            this.selectTable = r;
          }}
          filterable
          onFilteredChange={this.onFilteredChange}
          className="-highlight users_table"
          data={rides}
          showPaginationBottom={rides.length > 50}
          pageSize={50}
          style={{
            height: `${tableHeight}px`,
          }}
          collapseOnDataChange={false}
          onExpandedChange={(newExpanded) => {
            const oldExpanded = this.state.expandedRows;
            let newExpandedArray = [];
            for (const [key, value] of Object.entries(newExpanded)) {
              newExpandedArray[key] = Boolean(value);
            }
            const expandedRowIndex = newExpandedArray.findIndex(
              (rowIsExpanded, index) => rowIsExpanded && !oldExpanded[index]
            );
            this.setState({ expandedRows: newExpandedArray });
            if (expandedRowIndex === -1) {
              // Table row was collapsed, do nothing
              return;
            }
            const ride = rides[expandedRowIndex];
            this.loadGroupRides(ride);
          }}
          columns={[
            {
              expander: true,
              Header: 'Group Ride',
              headerClassName: 'wordwrap',
              id: 'is_group_ride',
              accessor: (d) => (d.group_rides && d.group_rides.length > 0 ? `Yes` : ''),
              width: 60,
              maxWidth: 60,
              Expander: ({ isExpanded, ...rest }) => {
                if (!rest.original.group_rides || rest.original.group_rides.length == 0) {
                  return null;
                } else {
                  return <span>{isExpanded ? <span>&#x2299;</span> : <span>&#x2295;</span>}</span>;
                }
              },
            },
            {
              Header: 'Vehicle',
              id: 'vehicle_name',
              accessor: (d) =>
                d.vehicle ? `${d.vehicle.model_name} ${d.vehicle.name}` : d.vehicle_name,
              maxWidth: 150,
              Cell: (props) => {
                const isGuestRide = props.original.guest != null;
                let style = isGuestRide ? 'underline scooterLink' : 'underline scooterLink';
                return (
                  <div
                    onClick={(event) => {
                      onClickScooter(props.original.vehicle.hash);
                      event.stopPropagation();
                    }}>
                    <span className={style}>{props.value}</span>
                  </div>
                );
              },
            },
            {
              Header: 'Zone',
              accessor: 'start_zone_name',
              maxWidth: 100,
              Cell: (props) => (
                <div
                  className="underline scooterLink"
                  onClick={(event) => {
                    onClickRideMap(props.original);
                    event.stopPropagation();
                  }}>
                  {props.value}
                </div>
              ),
            },
            {
              Header: 'Start (Pacific)',
              headerClassName: 'wordwrap',
              accessor: 'start_time',
              Cell: (props) => (
                <Fragment>
                  {props.value &&
                    moment(props.value).tz('America/Los_Angeles').format('MMM DD YYYY, h:mm a')}
                </Fragment>
              ),
              maxWidth: 175,
              sortMethod: (a, b) => {
                if (a === b) {
                  return 0;
                }
                return moment(a).isAfter(b) ? 1 : -1;
              },
            },
            {
              Header: 'End (Pacific)',
              headerClassName: 'wordwrap',
              accessor: 'end_time',
              Cell: (props) => (
                <Fragment>
                  {props.value &&
                    moment(props.value).tz('America/Los_Angeles').format('MMM DD YYYY, h:mm a')}
                </Fragment>
              ),
              maxWidth: 175,
              sortMethod: (a, b) => {
                return a === b ? 0 : moment(a).isAfter(b) ? 1 : -1;
              },
            },
            {
              Header: 'Duration',
              accessor: 'duration',
              Cell: (props) => <Fragment>{displayDuration(props.value)}</Fragment>,
              maxWidth: 70,
              sortMethod: (a, b) => {
                if (a === b) {
                  return 0;
                }
                return a < b ? 1 : -1;
              },
            },
            {
              Header: 'Ride Charge',
              headerClassName: 'wordwrap',
              accessor: 'charged_amount',
              maxWidth: 70,
              className: 'currency',
              Cell: (props) => {
                return <Fragment>{penniesToDollars(props.value)}</Fragment>;
              },
              sortMethod: (a, b) => {
                if (a === b) {
                  return 0;
                }
                return a < b ? 1 : -1;
              },
            },
            {
              id: 'total_charge',
              Header: 'Total Charge',
              headerClassName: 'wordwrap',
              maxWidth: 70,
              className: 'currency',
              accessor: (d) => {
                if (!d.group_rides || d.group_rides.length === 0) {
                  return d.charged_amount;
                }
                return (
                  d.charged_amount +
                  d.group_rides.reduce((prev, curr) => prev + curr.charged_amount, 0)
                );
              },
              Cell: (props) => <Fragment>{penniesToDollars(props.value)}</Fragment>,
              sortMethod: (a, b) => {
                if (a === b) {
                  return 0;
                }
                return a < b ? 1 : -1;
              },
            },
            {
              Header: 'Invoice',
              accessor: 'invoice',
              maxWidth: 125,
              width: 125,
              Cell: (props) => {
                return (
                  <div style={{ textAlign: 'center' }}>
                    <input
                      type="button"
                      value="Invoice"
                      onClick={(event) => {
                        event.stopPropagation();
                        onClickInvoice && onClickInvoice(props.original);
                      }}
                    />
                  </div>
                );
              },
            },
            {
              Header: 'Ride End Reason',
              headerClassName: 'wordwrap',
              accessor: 'ride_end_reason',
              width: 115,
              maxWidth: 115,
            },
            {
              Header: 'Payment Status',
              headerClassName: 'wordwrap',
              accessor: 'payment_status',
              maxWidth: 100,
              Cell: (props) => <div style={{ textAlign: 'center' }}>{props.value}</div>,
            },
            {
              Header: 'Refund',
              accessor: 'payment_status',
              maxWidth: 125,
              width: 125,
              Cell: (props) => (
                <div style={{ textAlign: 'center' }}>
                  {props.value === 'paid' &&
                    props.original.charged_amount > 0 &&
                    new Date(props.original.end_time) > new Date('2019-07-29') && (
                      <input
                        type="button"
                        value="Refund"
                        onClick={(event) => {
                          this.props.refundRide(props.original.id);
                          event.stopPropagation();
                        }}
                      />
                    )}
                </div>
              ),
            },
            {
              Header: 'End Ride Photo',
              headerClassName: 'wordwrap',
              accessor: 'end_ride_photo_id',
              width: 100,
              Cell: (props) => (
                <div style={{ textAlign: 'center' }}>
                  {props.value !== null && (
                    <img
                      src={ridePhotoImage}
                      alt="Close Modal Button"
                      onClick={(event) => {
                        onClickRidePhoto(props.original);
                        event.stopPropagation();
                      }}
                      className=""
                      style={{
                        paddingTop: '3px',
                        alignSelf: 'center',
                      }}
                    />
                  )}
                </div>
              ),
            },
          ]}
          SubComponent={({ original }) => {
            const groupRides = this.state.groupRides[original.id] || [];
            return (
              <GuestRides
                onClickInvoice={onClickInvoice}
                onClickRideMap={onClickRideMap}
                onClickRidePhoto={onClickRidePhoto}
                onClickScooter={onClickScooter}
                onClickRefund={this.refundGuestRide}
                rides={groupRides}
              />
            );
          }}
          defaultSorted={[
            {
              id: 'end_time',
              desc: true,
            },
          ]}
        />
      </div>
    );
  }
}

Rides.propTypes = {
  rides: PropTypes.array.isRequired,
  onClickInvoice: PropTypes.func,
  onClickRideMap: PropTypes.func.isRequired,
  onClickRidePhoto: PropTypes.func.isRequired,
  onClickScooter: PropTypes.func.isRequired,
  refundRide: PropTypes.func.isRequired,
  tableHeight: PropTypes.number,
};
