/* eslint-disable no-alert */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import { DateTimePicker } from 'react-widgets';
import { getVehicleRides } from '../../state/vehicle_rides';
import { getPricingPlans } from '../../state/pricingPlans';
import { getRidePhoto, sendPhotoReview } from '../../state/ride_photos';
import { getRefunds } from '../../state/refunds';
import { clearUser, getUser, getRidesForUser, patchUser } from '../../state/user';
import {
  endRide,
  getRefundForRide,
  refundRide,
  refundPartialRide,
  getRideLocations,
  getRideById,
} from '../../state/rides';
import { getFeatures } from '../../state/zones';
import AuthenticatedPage from '../../Components/AuthenticatedPage';
import { connect } from 'react-redux';
import { DropdownList, NumberPicker } from 'react-widgets';
import simpleNumberLocalizer from 'react-widgets-simple-number';
import Loader from '../../Components/Loader';
import { bindActionCreators } from 'redux';
import FindUser from '../../Components/FindUser';
import ReactTable from 'react-table';
import { INVOICE, SCOOTER_RIDE, RIDE_MAP, RIDE_PHOTO } from '../../util/constants';
import { dollarsToPennies, getTimeInterval } from '../../util/helper';
import Map from '../../Components/ZoneMap';
import Refunds from '../../Components/Refunds';
import Rides from '../../Components/Rides';
import RideDetails from '../../Components/RideDetails';
import { displayDuration, penniesToDollars } from '../../util/helper';

import closeButtonImage from '../../images/close.png';
import RidePhotoReview from '../../Components/RidePhotoReview';

import './edit.scss';
import momentLocalizer from 'react-widgets-moment';
import { CurrentRides } from '../../Components/CurrentRides';
import { UnpaidRides } from '../../Components/UnpaidRides';
import { clearUnpaidRideBalance } from '../../api';

const { REACT_APP_API_HOST: API_HOST } = process.env;
const API_HOST_V2 = `${API_HOST}/api/v2`;
const moment = require('moment-timezone');

moment.locale('en');
momentLocalizer();
simpleNumberLocalizer();

class EditUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      user: null,
      feedback: '',
      disabled: false,
      free_rides: 0,
      is_active: true,
      selected_pricing_plan_id: undefined,
      loaded: true,
      balance_owing: 0,
      submitBtnVal: 'Refresh User',
      open: false,
      selectedRide: {},
      mapPlaces: [],
      mapCenter: {},
      mapZoneBoundary: [],
      ride_photo: '',
      surcharge: '',
      receives_notifications: '',
    };
    this.props.actions.getPricingPlans();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.user && nextProps.user.id) {
      const {
        date_of_birth,
        email,
        is_active,
        free_rides,
        pricing_plan: { id: selected_pricing_plan_id },
      } = nextProps.user;
      const stateParams = {
        email: email || '',
        is_active,
        free_rides,
        selected_pricing_plan_id,
      };
      if (date_of_birth) {
        stateParams.date_of_birth = moment(date_of_birth).toDate();
      }
      this.setState(stateParams);
      if (!this.props.user) {
        this.loadRidesTableData(nextProps.user.id);
        this.props.actions.getRefunds(nextProps.user.id);
      }
    }
  }

  loadRidesTableData = (userId) => {
    this.setState({
      loaded: false,
    });
    this.props.actions.getRidesForUser(userId).then(() => {
      this.setState({
        loaded: true,
      });
    });
  };

  closePopup = () => {
    this.setState({
      open: false,
    });
  };

  onSubmitFindUser = (params) => {
    this.setState({ loaded: false });
    this.props.actions
      .getUser(params)
      .then(() => {
        this.setState({
          feedback: '',
        });
      })
      .catch(() => {
        this.setState({
          feedback: 'Could not find user',
          disabled: false,
          loaded: true,
        });
      });
  };

  onSubmitUser = async (event) => {
    event.preventDefault();
    try {
      const { user } = this.props;
      if (this.userDataChanged()) {
        this.setState({ feedback: '', disabled: false, loaded: false });
        const {
          date_of_birth,
          email,
          selected_pricing_plan_id: pricing_plan_id,
          free_rides,
          is_active,
        } = this.state;
        const params = {};
        const birthdate = moment(date_of_birth).format('YYYY-MM-DD');
        if (birthdate !== user.date_of_birth) {
          params.date_of_birth = birthdate;
        }
        if (email !== user.email) {
          params.email = email;
        }
        if (free_rides !== user.free_rides) {
          params.free_rides = free_rides;
        }
        if (is_active !== user.is_active) {
          params.is_active = is_active;
        }
        if (pricing_plan_id !== user.pricing_plan.id) {
          params.pricing_plan_id = pricing_plan_id;
        }
        await this.props.actions.patchUser(user.id, params);
      }
      const getUserParams = {
        phone_number: this.props.user.phone,
        country_code: this.props.user.country_code,
        user_id: this.props.user.id,
      };
      await this.props.actions.getUser(getUserParams);
      await this.props.actions.getRidesForUser(user.id);
      await this.props.actions.getRefunds(user.id);
      this.setState({
        feedback: 'User updated successfully',
        disabled: false,
        loaded: true,
      });
    } catch (error) {
      this.setState({
        loaded: true,
        feedback: error.detail || 'An unknown error occurred',
        disabled: false,
      });
    }
  };

  userDataChanged = () => {
    const {
      state: { date_of_birth, email, free_rides, is_active, selected_pricing_plan_id },
      props: { user },
    } = this;
    if (!user) {
      return false;
    }
    const dateOfBirthChanged = moment(user.date_of_birth).toDate() !== date_of_birth;
    const pricingPlanChanged = selected_pricing_plan_id !== user.pricing_plan.id;
    const freeRidesChanged = free_rides !== user.free_rides;
    const isActiveChanged = is_active !== user.is_active;
    const emailChanged = email !== user.email;
    return (
      dateOfBirthChanged ||
      pricingPlanChanged ||
      freeRidesChanged ||
      isActiveChanged ||
      emailChanged
    );
  };

  handleChangePricingPlan = (newPricingPlan) => {
    this.setState({
      selected_pricing_plan_id: newPricingPlan.id,
    });
  };

  handleChangeFreeRides = (newFreeRides) => {
    this.setState({
      free_rides: Math.max(0, newFreeRides),
    });
  };

  handleChangeIsActive = (event) => {
    const {
      target: { checked },
    } = event;
    this.setState({
      is_active: checked,
    });
  };

  handleChangeEmail = (event) => this.setState({ email: event.target.value });

  handleChangeBirthdate = (date_of_birth) => this.setState({ date_of_birth });

  clearForm = () => {
    this.props.actions.clearUser();
    this.setState({ feedback: '' });
  };

  clearUnpaidRideBalance = (rideId) => {
    this.setState({ loaded: false });
    clearUnpaidRideBalance(rideId)
      .then((response) => this.setState({ feedback: response.message }))
      .then(() => this.props.actions.getUser({ user_id: this.props.user.id }))
      .then(() => this.setState({ loaded: true }));
  };

  adminEndRide = (rideId) => {
    this.setState({ loaded: false });
    const { user } = this.props;
    const params = {
      phone_number: user.phone,
      country_code: user.country_code,
      user_id: user.id,
    };
    const endRideParams = {
      acknowledged_surcharge: false,
    };
    this.props.actions
      .endRide(rideId, endRideParams)
      .then(() => this.setState({ feedback: 'Ride ended' }))
      .then(() => this.props.actions.getUser(params))
      .then(() => this.props.actions.getRidesForUser(user.id))
      .then(() => this.setState({ loaded: true }))
      .catch(() => {
        this.setState({ feedback: 'There was a problem ending users ride', loaded: true });
      });
  };

  refundRide = (rideId) => {
    this.setState({ loaded: false });
    const params = {
      feedback: 'Admin cancellation reason...',
    };
    this.props.actions
      .refundRide(rideId, params)
      .then(() => this.props.actions.getRidesForUser(this.props.user.id))
      .then(() => {
        this.setState({ loaded: true });
        alert('Ride Successfully Refunded');
      })
      .catch((error) => {
        alert(error);
        this.setState({ loaded: true });
      });
  };

  onSubmitPartialRefund = (refundAmount) => {
    this.setState({ loaded: false });
    refundAmount = dollarsToPennies(refundAmount);
    const ride_id = this.state.selectedRide.id;
    const params = {
      refund_amount: refundAmount,
    };
    this.props.actions
      .refundPartialRide(ride_id, params)
      .then(() => this.props.actions.getRidesForUser(this.props.user.id))
      .then(() => {
        this.setState({ loaded: true });
        alert('Ride Successfully Refunded');
        this.closePopup();
      })
      .catch((error) => {
        alert(error);
        this.setState({ loaded: true });
        this.closePopup();
      });
  };

  addMarker = (formattedPlace) => {
    for (const value of formattedPlace) {
      const newPlace = {
        id: value.id,
        lat: value.lat,
        lng: value.lng,
        time: value.time,
        backgroundColor: value.backgroundColor,
        zindex: value.zindex,
      };
      this.setState({
        mapPlaces: [...this.state.mapPlaces, newPlace],
      });
    }
  };

  checkDuration = (start, end) => {
    const interval = getTimeInterval(start, end);
    if (interval < 1) {
      return true;
    } else {
      return false;
    }
  };

  openMapPopup = async (ride) => {
    const { start_time: start, end_location, end_time, start_zone_id: zone_id, id: ride_id } = ride;
    this.setState({
      popupType: RIDE_MAP,
      loaded: false,
      mapPlaces: [],
    });
    let map_zone = 0;
    let zone_boundary_points = [];
    let vehicleLocationCount = 0;
    let formattedPlaces = [];
    this.props.actions
      .getRideLocations(ride_id)
      .then(() => {
        let timeLbl = '';
        let lat = '';
        let lng = '';
        let zIndex = 0;
        let backgroundColor = '';
        let locations = Object.entries(this.props.rides.locations);
        vehicleLocationCount = locations.length - 1;
        formattedPlaces = locations.map((place, i) => {
          if (i === 0) {
            //start location, properties saved and pushed on to end of map places array after array of places is built so it shows on top
            this.setState({ mapCenter: { lat: place[1].latitude, lng: place[1].longitude } });
            timeLbl = 'Start - O min';
            lat = place[1].latitude;
            lng = place[1].longitude;
            backgroundColor = 'Green';
            // set zIndex of start marker to highest so it displays
            zIndex = vehicleLocationCount + 2;
            map_zone = zone_id;
            if (this.props.zones.length > 0) {
              this.props.zones.forEach((zone) => {
                if (zone.id === map_zone) {
                  zone.geofence.forEach((points) => {
                    const coords = {
                      lat: points[0],
                      lng: points[1],
                    };
                    zone_boundary_points.push(coords);
                  });
                }
              });
            }
            this.setState({
              mapZoneBoundary: zone_boundary_points,
            });
          } else if (i > 0 && i <= vehicleLocationCount - 1) {
            timeLbl = getTimeInterval(start, place[1].created_date);
            timeLbl = timeLbl + ' min';
            lat = place[1].latitude;
            lng = place[1].longitude;
            backgroundColor = 'Cornsilk';
            zIndex = i;
          } else if (i === vehicleLocationCount) {
            timeLbl = getTimeInterval(start, end_time);
            timeLbl = 'End - ' + timeLbl + ' min';
            lat = end_location.latitude;
            lng = end_location.longitude;
            backgroundColor = 'Red';
            zIndex = vehicleLocationCount + 1;
          }
          return {
            id: place[1].id,
            lat: lat,
            lng: lng,
            time: timeLbl,
            backgroundColor: backgroundColor,
            zindex: zIndex,
          };
        });
        this.setState({
          mapPlaces: formattedPlaces,
        });
      })
      .then(() => this.props.actions.getFeatures(zone_id))
      .then(() => {
        if (vehicleLocationCount > 0) {
          this.setState(
            { popupType: RIDE_MAP },
            this.setState({
              open: true,
              loaded: true,
            })
          );
        } else {
          alert('Error: There was an error loading the Ride Map for this ride.');
          this.setState({
            loaded: true,
          });
        }
      });
  };

  openRidePhotoPopup = (ride) => {
    this.props.actions.getRidePhoto(ride.id, ride.end_ride_photo_id).then(() => {
      if (this.props.ride_photos === 'Not Found') {
        alert('No image for this ride');
      } else {
        let photo_review = '';
        if (ride.end_ride_photo_review) {
          photo_review = ride.end_ride_photo_review.review;
        } else {
          photo_review = null;
        }
        this.setState(
          {
            popupType: RIDE_PHOTO,
            photo_ride_id: ride.id,
            photo_user_id: ride.user_id,
            photo_vehicle_name: ride.vehicle_name,
            photo_user_phone: ride.user_phone,
            photo_id: ride.end_ride_photo_id,
            photo_review: photo_review,
          },
          this.setState({
            open: true,
            loaded: true,
          })
        );
      }
    });
  };

  openScooterPopup = (vehicle_id) => {
    this.setState({
      popupType: SCOOTER_RIDE,
      open: true,
      loaded: false,
    });
    this.props.actions
      .getVehicleRides(vehicle_id)
      .then(() => {
        this.setState({
          popupType: SCOOTER_RIDE,
          open: true,
          loaded: true,
        });
      })
      .catch(() => {
        alert('Error: There was an error loading the Scooter Rides popup');
        this.setState({ loaded: true });
      });
  };

  openInvoicePopup = async (val) => {
    this.setState({ loaded: false });
    const payment_status = val.payment_status;
    const ride_id = val.id;
    const params = {};
    params.ride_id = val.id;
    await this.props.actions.getRideById(params);
    //if ride is partially refunded, get the amount refunded to display on invoice popup
    if (payment_status === 'partially_refunded') {
      this.props.actions.getRefundForRide(ride_id).then(() => {
        this.setState({
          popupType: INVOICE,
          selectedRide: this.props.rides.ride[0],
          loaded: true,
          open: true,
        });
      });
    } else {
      this.setState({
        popupType: INVOICE,
        selectedRide: this.props.rides.ride[0],
        loaded: true,
        open: true,
      });
    }
  };

  loadPopup = () => {
    const ModalContainer = (props) => (
      <div className="modal">
        <div className="closeModalBtn">
          <img
            src={closeButtonImage}
            alt="Close Modal Button"
            onClick={this.closePopup}
            className=""
            style={{
              paddingTop: '3px',
              alignSelf: 'center',
            }}
          />
        </div>
        {props.children}
      </div>
    );
    const type = this.state.popupType;
    switch (type) {
      case INVOICE:
        return (
          <ModalContainer>
            <RideDetails
              ride={this.state.selectedRide}
              onSubmitPartialRefund={this.onSubmitPartialRefund}
              restrictRefund="false"
              showPartialRefund="true"
            />
          </ModalContainer>
        );
      case SCOOTER_RIDE:
        return (
          <ModalContainer>
            <div className="scooterRidesTable">{this.scooterRidesTable(this.props.rides)}</div>
          </ModalContainer>
        );
      case RIDE_PHOTO:
        return (
          <ModalContainer>
            <RidePhotoReview
              rideId={this.state.photo_ride_id}
              userId={this.state.photo_user_id}
              userPhone={this.state.photo_user_phone}
              vehicleName={this.state.photo_vehicle_name}
              review={this.state.photo_review}
              photoId={this.state.photo_id}
              postReview={this.postReview}
              photoUrl={`${API_HOST_V2}/rides/${this.state.photo_ride_id}/photos/${this.state.photo_id}`}
            />
          </ModalContainer>
        );
      case RIDE_MAP:
        return (
          <Map
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAJIYWGel69VKdzFDmRV3fYZay_pO36GOk&v=3.exp&libraries=geometry,drawing,places"
            loadingElement={<div style={{ height: '100%' }} />}
            containerElement={<div style={{ height: '600px' }} />}
            mapElement={<div style={{ height: '95%' }} />}
            center={this.state.mapCenter}
            zoom={16}
            places={this.state.mapPlaces}
            parkingSpots={this.props.features.parkingSpots}
            restrictedAreas={this.props.features.restrictedAreas.filter((ra) => ra.show_on_map)}
            showParkingSpots={true}
            showPolygons={true}
            mapZoneBoundary={this.state.mapZoneBoundary}
            showControls={true}
          />
        );
      default:
        return null;
    }
  };

  postReview = (rideId, photoId, review) => {
    const params = {
      review_type: review,
    };
    this.props.actions.sendPhotoReview(rideId, photoId, params).then(() => {
      this.closePopup();
      alert('Photo successfully reviewed');
      this.loadRidesTableData();
    });
  };

  scooterRidesTable = () => {
    return (
      <ReactTable
        ref={(r) => {
          this.selectTable = r;
        }}
        filterable
        onFilteredChange={this.onFilteredChange}
        className="-striped -highlight users_table user_table_add_on "
        data={this.props.vehicle_rides}
        showPaginationBottom={false}
        pageSize={this.props.vehicle_rides.length}
        columns={[
          {
            Header: 'Zone',
            accessor: 'start_zone_name',
            maxWidth: 75,
          },
          {
            Header: 'User Id',
            accessor: 'user_id',
            width: 190,
            Cell: (props) => <div style={{ textAlign: 'center' }}>{props.value}</div>,
          },
          {
            Header: 'Start (Pacific)',
            accessor: 'created_date',
            Cell: (props) => (
              <Fragment>
                {props.value &&
                  moment(props.value).tz('America/Los_Angeles').format('MMM DD YYYY, h:mm a')}
              </Fragment>
            ),
            maxWidth: 175,
            sortMethod: (a, b) => {
              if (a === b) {
                return 0;
              }
              return moment(a).isBefore(b) ? 1 : -1;
            },
          },
          {
            Header: 'Duration',
            accessor: 'duration',
            Cell: (props) => <Fragment>{displayDuration(props.value)}</Fragment>,
            maxWidth: 50,
            sortMethod: (a, b) => {
              if (a === b) {
                return 0;
              }
              return a < b ? 1 : -1;
            },
          },
          {
            Header: 'Charged',
            accessor: 'charged_amount',
            maxWidth: 70,
            className: 'currency',
            Cell: (props) => <Fragment>{penniesToDollars(props.value)}</Fragment>,
            sortMethod: (a, b) => {
              if (a === b) {
                return 0;
              }
              return a < b ? 1 : -1;
            },
          },
          {
            Header: 'Ride End Reason',
            accessor: 'ride_end_reason',
            maxWidth: 300,
          },
          {
            Header: 'Payment Status',
            accessor: 'payment_status',
            maxWidth: 70,
            Cell: (props) => <div style={{ textAlign: 'center' }}>{props.value}</div>,
          },
        ]}
        defaultSorted={[
          {
            id: 'created_date',
            desc: false,
          },
        ]}
      />
    );
  };

  render() {
    const { user, pricingPlans, restrictedAccess } = this.props;
    const { date_of_birth, email, free_rides, is_active, selected_pricing_plan_id } = this.state;
    const [selectedPricingPlan] = pricingPlans.filter(
      (plan) => plan.id === selected_pricing_plan_id
    );
    const submitButtonLabel = this.userDataChanged() ? 'Update User' : 'Refresh User';
    const userForm = !user ? (
      <FindUser onSubmit={this.onSubmitFindUser} />
    ) : (
      <form onSubmit={this.onSubmitUser}>
        <div className="form_input_section">
          <label className="form_input_section__label" htmlFor="user_id">
            User ID
          </label>
          <input type="text" name="user_id" placeholder="" value={user.id} readOnly={true} />
        </div>
        <div className="form_input_section">
          <label className="form_input_section__label" htmlFor="name">
            Name
          </label>
          <input
            type="text"
            name="name"
            placeholder="Name"
            value={`${user.first_name || ''} ${user.last_name || ''}`}
            readOnly={true}
          />
        </div>
        <div className="form_input_section">
          <label className="form_input_section__label" htmlFor="phone">
            Phone Number
          </label>
          <input type="text" name="phone" placeholder="" value={user.phone} readOnly={true} />
        </div>
        <div className="form_input_section">
          <label className="form_input_section__label" htmlFor="date_of_birth">
            Date of Birth
          </label>
          <DateTimePicker
            placeholder="yyyy-mm-dd"
            date={true}
            time={false}
            value={date_of_birth}
            onChange={this.handleChangeBirthdate}
          />
        </div>
        <div className="form_input_section">
          <label className="form_input_section__label" htmlFor="email">
            Email Address
          </label>
          <input
            type="text"
            name="email"
            placeholder=""
            value={email}
            onChange={this.handleChangeEmail}
          />
        </div>
        {selectedPricingPlan && (
          <div className="form_input_section">
            <label className="form_input_section__label" htmlFor="pricing_plan">
              Pricing Plan
            </label>
            <DropdownList
              disabled={restrictedAccess}
              className="edit_user__pricing_plan_selector"
              data={pricingPlans}
              value={selectedPricingPlan}
              textField={(item) => {
                return `${item.name}: ${penniesToDollars(
                  item.base_price
                )} to start/${penniesToDollars(item.per_minute_price)} per minute`;
              }}
              onChange={this.handleChangePricingPlan}
              placeholder=""
            />
          </div>
        )}
        <div className="form_input_section">
          <label className="form_input_section__label" htmlFor="free_rides">
            Free Rides
          </label>
          <NumberPicker
            disabled={restrictedAccess}
            className="edit_user__pricing_plan_selector"
            defaultValue={0}
            value={free_rides}
            onChange={this.handleChangeFreeRides}
          />
        </div>
        <div className="form_input_section" style={{ alignItems: 'center' }}>
          <label className="form_input_section__label" htmlFor="balance_owing">
            Balance Owing
          </label>
          <input
            type="text"
            name="balance_owing"
            placeholder=""
            value={penniesToDollars(this.props.user.balance_owing)}
            readOnly={true}
            style={{ width: '5rem' }}
          />
        </div>
        <div className="form_input_section">
          <label className="form_input_section__label" htmlFor="is_active">
            Is Active
          </label>
          <input
            disabled={restrictedAccess}
            type="checkbox"
            name="is_active"
            value="is_active"
            checked={is_active}
            onChange={this.handleChangeIsActive}
          />
        </div>
        <div className="form_input_section">
          <label className="form_input_section__label" htmlFor="is_active">
            Receives Notifications
          </label>
          <b>{this.props.user.user_device_token !== null ? 'YES' : 'NO'}</b>
        </div>
        <div className="form_input_section">
          <input type="submit" value={submitButtonLabel} disabled={this.state.disabled} />
          <input type="button" value="Clear User" onClick={this.clearForm} />
        </div>
      </form>
    );
    return (
      <AuthenticatedPage className="edit_vehicle_container" onClick={this.closePopup}>
        <div className="page-title">User Lookup</div>
        {userForm}
        <p>{this.state.feedback}</p>
        <Popup
          open={this.state.open}
          closeOnDocumentClick
          onClose={this.closePopup}
          overlayStyle={{ backgroundColor: 'light-grey' }}
          contentStyle={{ maxHeight: '100vh', overflowY: 'auto' }}>
          {this.loadPopup}
        </Popup>
        {this.props.user && (
          <Fragment>
            <UnpaidRides
              canClearBalances={!restrictedAccess}
              clearUnpaidRide={this.clearUnpaidRideBalance}
              unpaidRides={this.props.user.unpaid_rides}
            />
            <CurrentRides adminEndRide={this.adminEndRide} ride={this.props.user.ride} />
            <Refunds refunds={this.props.refunds} />
            <Loader loaded={this.state.loaded} />
            <Rides
              onClickInvoice={this.openInvoicePopup}
              onClickRideMap={this.openMapPopup}
              onClickRidePhoto={this.openRidePhotoPopup}
              onClickScooter={this.openScooterPopup}
              rides={this.props.user.rides.filter((ride) => ride.end_time != null)}
              refundRide={this.refundRide}
              loadRidePhoto={this.loadRidePhoto}
            />
          </Fragment>
        )}
      </AuthenticatedPage>
    );
  }
}

EditUser.propTypes = {
  actions: PropTypes.object,
  features: PropTypes.object,
  pricingPlans: PropTypes.array,
  refunds: PropTypes.array,
  restrictedAccess: PropTypes.bool,
  rides: PropTypes.array,
  ride_photos: PropTypes.array,
  user: PropTypes.object,
  vehicle_rides: PropTypes.array,
  restrictedAreas: PropTypes.array,
  zones: PropTypes.array,
};

const mapStateToProps = (state) => {
  const {
    admin,
    pricingPlans,
    user,
    rides,
    ride_photos,
    vehicle_rides,
    zones: { features, zones },
    refunds,
    restricted_areas: { restrictedAreas },
  } = state;
  const restrictedAccess = admin && admin.restricted_access;
  return {
    pricingPlans,
    restrictedAccess,
    features,
    user,
    rides,
    ride_photos,
    vehicle_rides,
    zones,
    refunds,
    restrictedAreas,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        getFeatures,
        getVehicleRides,
        getPricingPlans,
        getRefundForRide,
        getUser,
        getRidesForUser,
        clearUser,
        patchUser,
        endRide,
        refundRide,
        refundPartialRide,
        getRideById,
        getRideLocations,
        getRefunds,
        getRidePhoto,
        sendPhotoReview,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);
