import { Link } from 'gatsby';
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { displayDuration, dollarsToPennies, penniesToDollars } from '../../util/helper';
import moment from 'moment-timezone';
moment.locale('en');

import './index.scss';

class Invoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refundAmount: '0.00',
      submitDisabled: true,
      feedback: '',
    };
  }
  formatUserAgent = (user_agent) => {
    if (user_agent) {
      let formattedUserAgent = user_agent.slice(16);
      return formattedUserAgent;
    }
  };

  getSurcharges = (ride) => {
    const price = ride.price;
    let surcharges = price && price.surcharges ? price.surcharges : [];
    let surcharged = surcharges.map((surcharge, index) => {
      return (
        <div className="line_item" key={`surcharge_${index}`}>
          <p>{surcharge.description}</p>
          <p>{penniesToDollars(surcharge.amount)}</p>
        </div>
      );
    });
    return surcharged;
  };

  onSubmitPartialRefund = (event) => {
    event.preventDefault();
    this.props.onSubmitPartialRefund && this.props.onSubmitPartialRefund(this.state.refundAmount);
  };

  onChangeRefundAmount = (event) => {
    const refundString = event.target.value;
    const refundAmount = dollarsToPennies(refundString);
    if (refundAmount === 0) {
      return this.setState({ feedback: '', submitDisabled: true });
    }
    const maxRefundAmount = this.props.ride.charged_amount;
    if (refundAmount > maxRefundAmount) {
      return this.setState({ feedback: '(must be less than ride amount)', submitDisabled: true });
    } else if (refundAmount === maxRefundAmount) {
      return this.setState({
        feedback: 'To refund the entire ride amount, use the refund button in the ride table',
        submitDisabled: false,
      });
    }
    this.setState({ refundAmount: refundString, feedback: '', submitDisabled: false });
  };

  renderPartialRefund = (ride, restrictRefund) => {
    //Determine if refund has already been issued, and if so retrieve amount to display
    const payment_status = ride.payment_status;
    if (restrictRefund === 'false') {
      if (payment_status === 'refunded') {
        return (
          <Fragment>
            <h2>Refunded</h2>
            <p>This ride is already fully refunded </p>
          </Fragment>
        );
      } else if (payment_status === 'partially_refunded') {
        return (
          <Fragment>
            <h2>Partially Refunded</h2>
            <p> {this.state.previousRefundedAmount} already refunded </p>
          </Fragment>
        );
      } else {
        return (
          <form onSubmit={this.onSubmitPartialRefund}>
            <h2>Partial Refund</h2>
            <input
              type="text"
              className="partialRefundInput"
              placeholder="0.00"
              name="partialRefundAmt"
              onChange={this.onChangeRefundAmount}
            />
            <input type="submit" disabled={this.state.submitDisabled} />
            <p className="refundFeedback">{this.state.feedback}</p>
          </form>
        );
      }
    } else {
      if (payment_status === 'refunded') {
        return (
          <Fragment>
            <h2>Refund Information</h2>
            <p>This ride is already fully refunded </p>
          </Fragment>
        );
      } else if (payment_status === 'partially_refunded') {
        return (
          <Fragment>
            <h2>Partially Refunded</h2>
            <p> {this.state.previousRefundedAmount} already refunded </p>
          </Fragment>
        );
      }
    }
  };

  render() {
    const {
      props: { ride, restrictRefund },
    } = this;
    let battery_type = ride.vehicle.battery_type && ride.vehicle.battery_type === 4 ? ':DD' : '';
    const selectedVehicleModel = ride.vehicle.model_name;
    const perMinuteCharge = ride.price && ride.price.per_minute_price;
    const baseCharge = ride.price && ride.price.base_price;
    return (
      <div className="receiptDetails">
        <h2>
          <div className="rideDetails">
            {'Ride ID: '}
            <span> {ride.id}</span>
          </div>
          <Link to="/vehicles/edit" state={{ selectedScooter: { vehicle: ride.vehicle } }}>
            <div className="rideDetails">
              {'Scooter: '}
              <span className="textLink"> {ride.vehicle.name}</span>
            </div>
          </Link>
        </h2>
        <p className="invoiceStartZoneName">{ride.start_zone_name}</p>
        <div className="line_item">
          <p>Ride End</p>
          <p>{`${moment(ride.end_time).format('MMM DD YYYY, h:mm a')}`}</p>
        </div>
        <div className="line_item">
          <p>Ride Time </p>
          <p>{displayDuration(ride.duration)}</p>
        </div>
        <div className="line_item">
          <p>{`${selectedVehicleModel} ${battery_type} fare`}</p>
          <p>{penniesToDollars(ride.amount)}</p>
        </div>
        <p className="invoiceTextPriceDetails">
          {penniesToDollars(baseCharge)} + {penniesToDollars(perMinuteCharge)}/min{' '}
        </p>
        <h3>TAXES & FEES</h3>
        {this.getSurcharges(ride)}
        <div className="line_item">
          <p className="invoiceTextTotal">TOTAL</p>
          <p className="invoiceTextTotal">{penniesToDollars(ride.charged_amount)}</p>
        </div>
        <hr />
        <h3>PAYMENT STATUS</h3>
        <p>
          {ride.payment_status} ({ride.payment_method})
        </p>
        <h3>APP VERSION & DEVICE</h3>
        <p>{`v${this.formatUserAgent(ride.user_agent)}`}</p>
        <h3>RIDE END REASON</h3>
        <p>{ride.ride_end_reason}</p>
        <h3>ACKNOWLEDGED SURCHARGE</h3>
        <p>{`${ride.acknowledged_surcharge ? 'true' : 'false'}`}</p>
        <hr />
        {this.props.showPartialRefund && this.renderPartialRefund(ride, restrictRefund)}
      </div>
    );
  }
}

Invoice.propTypes = {
  onSubmitPartialRefund: PropTypes.func,
  ride: PropTypes.object,
  restrictRefund: PropTypes.bool,
  showPartialRefund: PropTypes.func,
};

export default Invoice;
