import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import { penniesToDollars, currencyToNumber } from '../../util/helper';

import './index.scss';

const moment = require('moment-timezone');

class Refunds extends Component {
  render() {
    const { refunds = [] } = this.props;
    return refunds.length === 0 ? (
      <h2>No Refunds</h2>
    ) : (
      <Fragment>
        <h2>Refunds</h2>
        <ReactTable
          ref={(r) => {
            this.selectTable = r;
          }}
          className="  users_table refund_table"
          style={{ height: refunds.length < 10 ? '150px' : '400px' }}
          data={refunds}
          pageSize={refunds.length}
          getTheadThProps={() => {
            return { style: { outline: 0 } };
          }}
          showPagination={false}
          columns={[
            {
              Header: 'Date',
              id: 'created_date',
              accessor: ({ created_date }) =>
                created_date &&
                moment(created_date).tz('America/Los_Angeles').format('MMM DD YYYY, h:mm a'),
              width: 250,
              maxWidth: 250,
              sortMethod: (a, b) => (moment(a).isAfter(moment(b)) ? 1 : -1),
            },
            {
              Header: 'Amount',
              id: 'refund_amount',
              accessor: ({ refund_amount }) => penniesToDollars(refund_amount),
              width: 100,
              maxWidth: 100,
              className: 'centered',
              sortMethod: (a, b) => (currencyToNumber(a) > currencyToNumber(b) ? 1 : -1),
            },
            {
              Header: 'Refund Reason',
              accessor: 'refund_reason',
              width: 150,
              className: 'centered',
            },
          ]}
          defaultSorted={[
            {
              id: 'created_date',
              desc: true,
            },
          ]}
        />
      </Fragment>
    );
  }
}

Refunds.propTypes = {
  refunds: PropTypes.array,
};

export default Refunds;
