import React, { Component, Fragment } from 'react';
import Moment from 'moment';
import momentLocalizer from 'react-widgets-moment';

import './index.scss';

Moment.locale('en');
momentLocalizer();

class FindUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      country_code: '1',
      phone_number: '',
      user_id: '',
      disabled: true,
    };
  }

  onChange = (event) => {
    let { name, value } = event.target;
    if (name === 'country_code') {
      value = Math.max(1, value);
    }
    this.setState({ [name]: value }, () => {
      const { country_code, phone_number, user_id } = this.state;
      const disabled = !(
        (parseInt(country_code, 10) > 0 && phone_number.length > 0) ||
        user_id.length > 0
      );
      this.setState({ disabled });
    });
  };

  onSubmit = (event) => {
    event.preventDefault();
    const { country_code, phone_number, user_id } = this.state;
    this.props.onSubmit && this.props.onSubmit({ country_code, phone_number, user_id });
  };

  reset = () => {
    this.setState({
      disabled: true,
    });
  };

  render() {
    const { disabled, phone_number, country_code = 1, user_id } = this.state;
    return (
      <Fragment>
        <h3>Locate a user by their phone number or user ID</h3>
	<h3>A User ID can be found on the Stripe Receipt for the ride, or directly in the customer service portal!</h3>
        <p>
          Search by country code and phone number <strong>or</strong> by User ID. If both are
          provided, search will be by country code/phone number.
        </p>
        <form onSubmit={this.onSubmit} className="add_pricing_plan_form">
          <div className="form_input_section">
            <label className="add_pricing_plan_form__label" htmlFor="country_code">
              Country Code
            </label>
            <input
              className="add_pricing_plan_form__data_input"
              type="number"
              name="country_code"
              placeholder=""
              value={country_code}
              onChange={this.onChange}
              autoComplete="randomStringHack"
            />
          </div>
          <div className="form_input_section">
            <label className="add_pricing_plan_form__label" htmlFor="phone_number">
              Phone Number
            </label>
            <input
              className="add_pricing_plan_form__data_input"
              type="text"
              name="phone_number"
              placeholder=""
              value={phone_number}
              onChange={this.onChange}
            />
          </div>
          <div className="form_input_section">
            <label className="add_pricing_plan_form__label" htmlFor="user_id">
              User ID
            </label>
            <input
              className="add_pricing_plan_form__data_input"
              type="text"
              name="user_id"
              placeholder=""
              value={user_id}
              onChange={this.onChange}
            />
          </div>
          <input type="submit" disabled={disabled} />
        </form>
      </Fragment>
    );
  }
}

export default FindUser;
