import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const moment = require('moment-timezone');

export const CurrentRides = ({ adminEndRide, ride }) => {
  const onClick = (rideId) => adminEndRide && adminEndRide(rideId);

  if (!ride) {
    return <h2>No Current Ride(s)</h2>;
  }

  const userRide = {
    id: ride.id,
    rideType: ride.group_rides && ride.group_rides.length > 0 ? 'Host' : '',
    vehicleName: ride.vehicle.name,
    startZoneName: ride.start_zone_name,
    startTime: ride.start_time,
    userAgent: ride.user_agent,
  };

  // Show only active group rides, and normalize data
  const guestRides = ride.group_rides
    ? ride.group_rides
        .filter(({ end_time }) => !end_time)
        .map((ride) => {
          return {
            ...userRide,
            id: ride.id,
            rideType: 'Guest',
            vehicleName: ride.vehicle_name,
            startTime: ride.start_time,
          };
        })
    : [];

  const allRides = [userRide, ...guestRides];

  return (
    <Fragment>
      <h2>Current Ride(s)</h2>
      <table className="currentRideTable">
        <thead>
          <tr>
            <th>Ride Type</th>
            <th>Vehicle</th>
            <th>Zone</th>
            <th>Start (Pacific)</th>
            <th>App Version &amp; Device</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {allRides.map((ride) => {
            return (
              <tr key={ride.id}>
                <td>{ride.rideType}</td>
                <td>{ride.vehicleName}</td>
                <td>{ride.startZoneName}</td>
                <td>
                  {ride.startTime &&
                    moment(ride.startTime).tz('America/Los_Angeles').format('MMM DD YYYY, h:mm a')}
                </td>
                <td>{ride.userAgent}</td>
                <td>
                  {(ride.rideType === 'Guest' ||
                    ride.rideType === '' ||
                    (ride.rideType === 'Host' && guestRides.length === 0)) && (
                    <input
                      type="button"
                      className="end_ride_button"
                      value="End This Ride"
                      onClick={() => onClick(ride.id)}
                    />
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Fragment>
  );
};

CurrentRides.propTypes = {
  adminEndRide: PropTypes.func,
  ride: PropTypes.object,
};
