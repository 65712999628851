import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { penniesToDollars } from '../../util/helper';
const moment = require('moment-timezone');

export const UnpaidRides = ({ canClearBalances = false, unpaidRides = [], clearUnpaidRide }) => {
  const onClick = (rideId) => clearUnpaidRide && clearUnpaidRide(rideId);

  return unpaidRides.length == 0 ? (
    <Fragment />
  ) : (
    <Fragment>
      <h2>Unpaid Rides</h2>
      <table className="currentRideTable">
        <thead>
          <tr>
            <th>Ride Type</th>
            <th>Vehicle</th>
            <th>Zone</th>
            <th>Start (Pacific)</th>
            <th>End (Pacific)</th>
            <th>Charged Amount</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {unpaidRides.map((ride) => {
            const rideType = ride.is_host_ride ? 'Host' : ride.is_guest_ride ? 'Guest' : '';
            return (
              <tr key={ride.id}>
                <td>{rideType}</td>
                <td>{ride.vehicle_name}</td>
                <td>{ride.start_zone_name}</td>
                <td>
                  {ride.start_time &&
                    moment(ride.start_time).tz('America/Los_Angeles').format('MMM DD YYYY, h:mm a')}
                </td>
                <td>
                  {ride.end_time &&
                    moment(ride.end_time).tz('America/Los_Angeles').format('MMM DD YYYY, h:mm a')}
                </td>
                <td>{penniesToDollars(ride.charged_amount)}</td>
                <td>
                  {canClearBalances && (
                    <input
                      type="button"
                      className="end_ride_button"
                      value="Clear Balance"
                      onClick={() => onClick(ride.id)}
                    />
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Fragment>
  );
};

UnpaidRides.propTypes = {
  canClearBalances: PropTypes.bool,
  clearUnpaidRide: PropTypes.func,
  unpaidRides: PropTypes.array,
};
